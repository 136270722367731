export const Logo = () => {
    return (
        <svg width="170" height="100" viewBox="0 0 411 96" fill="none" xmlns="http://www.w3.org/2000/svg" fontWeight="900"  >
        <path d="M236.228 24.1394C236.408 22.1094 236.738 20.8294 236.598 19.6094C236.208 16.1294 237.628 14.9794 241.098 14.9994C244.648 15.0194 245.078 16.7094 245.058 19.6494C244.958 35.8994 245.148 52.1594 244.958 68.4194C244.848 78.5494 241.968 87.6794 232.028 92.4094C221.528 97.4094 211.048 96.5294 201.238 90.2194C196.648 87.2694 194.258 82.8394 193.978 77.7594C197.878 76.7594 200.778 76.8794 203.328 81.1394C207.158 87.5594 216.868 90.1094 224.788 87.7394C231.618 85.6894 236.088 79.9094 236.538 72.0994C236.768 68.0294 236.578 63.9394 236.578 58.1294C229.028 67.3594 220.678 70.8694 210.288 68.7694C204.278 67.5594 199.568 64.4294 195.908 59.6794C187.198 48.3894 188.758 29.0494 199.018 20.0894C209.498 10.9394 223.868 12.2594 236.218 24.1394H236.228ZM236.628 42.0694C236.818 29.9094 228.848 20.9694 217.738 20.8794C206.678 20.7894 198.718 29.0994 198.338 41.1294C197.968 52.7494 206.108 62.0694 216.818 62.2994C228.228 62.5394 236.438 54.1594 236.628 42.0694Z" fill="#43844C"/>
        <path d="M45.8318 53.8209C45.8318 52.0809 45.8318 50.3509 45.8318 48.6109C45.8318 34.1709 45.8518 19.7309 45.8118 5.29087C45.8118 2.49088 45.6018 0.0208751 49.7518 0.0708751C53.5418 0.120875 53.5118 2.30087 53.5018 4.97087C53.4718 24.7209 53.4918 44.4809 53.4718 64.2309C53.4718 65.5209 53.2718 66.8009 53.1518 68.1609C48.2718 69.4309 45.0518 68.5109 42.2018 64.0109C32.5218 48.7309 22.3618 33.7509 12.3618 18.6709C11.3918 17.2109 10.3218 15.8209 9.30184 14.3909C8.86184 14.4909 8.43184 14.5809 7.99184 14.6809V68.3109H0.251839C0.171839 66.6409 0.0218388 64.8909 0.0218388 63.1409C0.00183884 44.0609 -0.0381612 24.9709 0.0918388 5.88088C0.101839 4.02088 0.791839 0.880875 1.86184 0.550875C4.95184 -0.399125 8.21184 -0.469125 10.5218 3.17088C17.3918 13.9509 24.5418 24.5409 31.6018 35.2009C35.7918 41.5309 40.0118 47.8509 44.2218 54.1709C44.7518 54.0509 45.2918 53.9409 45.8218 53.8209H45.8318Z" fill="#43844C"/>
        <path d="M112.62 23.6703C112.78 20.9703 112.92 18.5703 113.06 16.2103C119.8 13.6703 121.27 14.5603 121.27 21.1103C121.27 36.1803 121.25 51.2603 121.23 66.3303C121.23 66.8203 121.09 67.3003 120.95 68.2103H113.15C112.98 65.3703 112.82 62.6203 112.57 58.4603C106.5 66.5303 99.1599 69.9703 90.1799 69.3003C83.2599 68.7903 77.4399 65.9203 72.9799 60.5003C64.0499 49.6403 64.6599 30.9203 74.2899 21.1803C84.1799 11.1703 98.1599 11.9703 112.63 23.6803L112.62 23.6703ZM112.88 41.4803C112.87 31.9503 108.28 24.6503 100.4 21.9903C93.2099 19.5603 86.4999 20.7103 81.0199 26.0103C74.4899 32.3203 73.8399 40.3703 76.0199 48.5703C78.6399 58.4303 87.4799 63.8003 97.3399 62.1803C106.76 60.6303 112.89 52.4703 112.88 41.4903V41.4803Z" fill="#43844C"/>
        <path d="M371.22 22.0802C377.05 15.2502 384.11 13.3902 392.19 14.2602C401.85 15.2902 409.24 21.9002 409.78 31.5002C410.41 42.7502 410.24 54.0502 410.14 65.3202C410.09 71.0602 405.69 67.7702 402.39 68.7602C402.39 59.3702 402.43 50.4702 402.37 41.5702C402.35 38.6002 402.43 35.5502 401.79 32.6802C399.91 24.2702 393.99 20.2802 384.84 20.8702C377.22 21.3602 371.47 26.6102 370.96 34.5702C370.37 43.8202 370.45 53.1202 370.32 62.4002C370.22 69.1602 370.26 69.1802 362.47 68.1002C362.47 64.1202 362.47 60.0302 362.47 55.9402C362.47 44.1602 362.44 32.3802 362.49 20.6002C362.5 18.1302 361.51 15.1402 365.96 15.0202C369.73 14.9202 370.5 16.8002 370.42 19.8702C370.41 20.3102 370.73 20.7602 371.24 22.0702L371.22 22.0802Z" fill="#43844C"/>
        <path d="M345.92 68.3903H338.61C338.28 66.1303 337.97 64.0603 337.58 61.4103C336.52 62.1403 335.71 62.5303 335.1 63.1303C328.07 69.9703 319.65 70.5503 311.03 67.5703C302.37 64.5803 298.47 57.5903 298.26 48.7003C298.03 38.9203 298.24 29.1303 298.11 19.3503C298.07 16.0903 299.4 15.0103 302.57 15.0403C306.12 15.0703 306 17.2303 305.99 19.6903C305.95 28.3103 305.93 36.9403 305.99 45.5603C306.06 55.3903 311.47 61.7703 320.03 62.2803C330.46 62.9103 336.73 57.8203 337.51 47.1403C338.16 38.2303 337.94 29.2603 338.06 20.3103C338.1 17.5303 337.96 15.0703 342.04 15.0203C346.21 14.9703 345.93 17.4503 345.93 20.2303C345.89 36.1203 345.91 52.0103 345.91 68.4003L345.92 68.3903Z" fill="#43844C"/>
        <path d="M131.801 15.7309C137.254 14.8709 140.791 16.6343 142.411 21.0209C146.661 32.5009 150.911 43.9709 155.191 55.4409C155.741 56.9209 156.421 58.3509 157.551 61.0009C158.641 58.4909 159.321 57.1209 159.851 55.6909C164.161 44.0609 168.431 32.4109 172.741 20.7809C175.091 14.4309 175.691 14.1409 183.041 15.9409C179.011 26.2909 175.021 36.5909 170.991 46.8709C168.511 53.1809 166.151 59.5609 163.301 65.7009C162.651 67.1109 160.191 68.4709 158.571 68.4609C156.291 68.4509 152.581 67.7409 151.971 66.2909C145.031 49.6909 138.561 32.8909 131.811 15.7409L131.801 15.7309Z" fill="#43844C"/>
        <path d="M269.041 68.4008H261.641C261.501 66.4808 261.251 64.7208 261.251 62.9608C261.221 49.0508 261.22 35.1308 261.24 21.2208C261.24 14.8508 261.591 14.6108 268.691 15.6508C268.901 17.7508 269.121 19.9708 269.431 23.1308C274.24 17.8608 278.89 13.4008 286.55 14.5408V21.2808C286.38 21.4008 286.14 21.7008 285.86 21.7608C271.99 24.3308 269.06 27.8508 269.05 42.0308C269.05 50.6308 269.05 59.2308 269.05 68.4208L269.041 68.4008Z" fill="#43844C"/>
        </svg>
        
    );
  }