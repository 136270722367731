import React from "react";
import { useState, useEffect } from "react";
import "./Home.scss";
import list_view from "../../assets/extras/menu.png";
import aboutus from "../../assets/extras/aboutus2.png";
import Slide from "react-reveal/Slide";
import Jump from "react-reveal/Jump";
import { GoGoal } from "react-icons/go";
import icon1 from "../../assets/extras/icon.one.png";
import icon2 from "../../assets/extras/solar.two.png";
import icon3 from "../../assets/extras/solar.three.png";
import icon4 from "../../assets/extras/solar.four.png";
import icon5 from "../../assets/extras/solar.five.png";
import icon6 from "../../assets/extras/solar.six.png";
import icon7 from "../../assets/extras/solar.seven.png";
import icon8 from "../../assets/extras/solar.eight.png";
import icon9 from "../../assets/extras/cylindical100.png";
import icon10 from "../../assets/extras/field reliability100.png";
import icon11 from "../../assets/extras/strong quality100.png";
import icon12 from "../../assets/extras/industry leading100.png";
import icon16 from "../../assets/extras/higherv power out put100.png";
import zero from "../../assets/extras/zero micro cracks100.png";
import icon13 from "../../assets/extras/pid100.png";
import icon14 from "../../assets/extras/bipass diodes100.png";
import icon15 from "../../assets/extras/120years warrenty100.png";
import data from "../../assets/extras/Form 1.png";
import data1 from "../../assets/extras/technicaldata1.png";
import icon17 from "../../assets/extras/minimal.png";
import icon20 from "../../assets/extras/icon.20.png";
import bifacial from "../../assets/extras/monomask.png";
import monofacial from "../../assets/extras/monofacial.png";
import certificate from "../../assets/extras/CERTIFICATIONS.png";
import residential from "../../assets/extras/residential.png";
import commercial from "../../assets/extras/commercial.png";
import utility from "../../assets/extras/utility.png";
import offgrid from "../../assets/extras/off grid.png";
import { Fade } from "react-reveal";
import pdf from "../../assets/extras/Whitepaper.pdf";
import CustomToast from "../../components/custom/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Logo } from "../../assets/Icons/logo";

const Home = () => {
  const [backgroundColor, setBackgroundColor] = useState("initial");
  const [textColor, setTextColor] = useState("white");
  const [sidenav, setSidenav] = useState(false);
  const [countData, setCountData] = useState({ data: 0 });
  const [count, setCount] = useState(0);

  console.log(countData);
  const handlesidenav = () => {
    setSidenav(!sidenav);
    console.log(!sidenav);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 200;
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (scrollPosition > scrollThreshold) {
        setBackgroundColor("white");
        setTextColor("green");
      } else {
        setBackgroundColor("initial");
        setTextColor("white");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(textColor, "textColor");
  const formDetails = {
    yourName: "",
    email: "",
    subject: "",
    mobileNumber: "",
    company: "",
    product: "",
    comment: "",
  };

  const [errors, setErrors] = useState({
    yourName: "",
    email: "",
    mobileNumber: "",
    company: "",
    product: "",
  });

  const [formData, setFormData] = useState(formDetails);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const showCustomToast = (message, type) => {
    CustomToast({ message, type });
  };

  const handleSubmit = async () => {
    let hasErrors = false;
    if (
      !formData.yourName.trim() ||
      !formData.email.trim() ||
      !formData.mobileNumber.trim() ||
      !formData.company.trim() ||
      !formData.product.trim()
    ) {
      hasErrors = true;
      setErrors({
        ...errors,
        yourName: !formData.yourName.trim() ? "Your name is required" : "",
        email: !formData.email.trim() ? "Email is required" : "",
        mobileNumber: !formData.mobileNumber.trim()
          ? "Mobile number is required"
          : "",
        company: !formData.company.trim() ? "Company name is required" : "",
        product: !formData.product.trim() ? "Product is required" : "",
      });
    } else {
      setErrors({
        yourName: "",
        email: "",
        mobileNumber: "",
        company: "",
        product: "",
      });
    }

    if (hasErrors) {
      showCustomToast("Please fill all required fields correctly", "error");
      return;
    }

    const baseUrl = "https://static.promena.in/SendMail";
    const queryParams = `?Id=1&email=${formData.email}&name=${formData.yourName}&phoneNumber=${formData.mobileNumber}&serviceType=${formData.product}&address=${formData.company}&description=${formData.comment}`;

    const url = `${baseUrl}${queryParams}`;

    const requestOptions = {
      method: "POST",
      headers: { Accept: "text/plain" },
    };

    try {
      const response = await fetch(url, requestOptions);
      if (response.status === 200) {
        showCustomToast("Email has been sent successfully", "success");
      } else {
        showCustomToast("Invalid email address", "error");
      }
    } catch (error) {
      console.error("Error in fetching data:", error);
    }
  };

  const handelDownload = () => {
    const pdfURL = pdf;
    const isDownloadSupported = "download" in document.createElement("a");

    if (isDownloadSupported) {
      const link = document.createElement("a");
      link.href = pdfURL;
      link.download = "Nithin Sai.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(pdfURL, "_blank");
    }
  };

  useEffect(() => {
    const storedCount = localStorage.getItem("refreshCount");
    if (storedCount) {
      setCount(parseInt(storedCount));
    }
    setCount((prevCount) => {
      const newCount = prevCount + 1;
      localStorage.setItem("refreshCount", newCount);
      return newCount;
    });
  }, []);

  return (
    <div>
      <div className="main">
        <div className="main_body">
          <div style={{ backgroundColor }} className="main_body_header">
            <div className="main_body_header_left">
              <div className="main_body_header_left_logo">
                <Logo />
              </div>
            </div>
            <div className="main_body_header_right">
              <div className="main_body_header_right_buttons">
                <a className="btn" style={{ color: textColor }} href="#H1">
                  Home
                </a>
                <a className="btn" style={{ color: textColor }} href="#C1">
                  About Us
                </a>
                <a className="btn" style={{ color: textColor }} href="#C2">
                  Products
                </a>
                <a className="btn" style={{ color: textColor }} href="#C3">
                  Contact Us
                </a>
              </div>
            </div>
            <div className="main_body_header_rightmob">
              <button onClick={handlesidenav}>
                <img src={list_view} alt="" />
              </button>
              {sidenav && (
                <div className="main_body_header_right_buttons">
                  <a className="btn" href="#H1">
                    Home
                  </a>
                  <a className="btn" href="#C1">
                    About Us
                  </a>
                  <a className="btn" href="#C2">
                    Products
                  </a>
                  <a className="btn" href="#C3">
                    Contact Us
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="main_body_banner" id="H1">
            <div className="main_body_banner_content">
              <h2>
                Illuminate Your Future <br />
                Power with solar
              </h2>

              <h3>High quality long lasting reliable Solar modules</h3>

              <p>
                Assembled with a Multi BB Mono PERC cell configuration,
                available in Mono and Bifacial options. The Bifacial double
                glass modules have the capability of converting incident light
                from both the rear and front sides into electricity, resulting
                in higher output power, a lower temperature coefficient, reduced
                shading loss, and enhanced tolerance for mechanical loading.
              </p>
              <h1>100% Automated European Production Line</h1>
              <div className="icons">
                <div className="icon">
                  <img src={residential} alt="" />
                  <h4>Residential</h4>
                </div>

                <div className="icon">
                  <img src={utility} alt="" />
                  <h4>Utility</h4>
                </div>
                <div className="icon">
                  <img src={commercial} alt="" />
                  <h4>Commercial</h4>
                </div>
                <div className="icon">
                  <img src={offgrid} alt="" />
                  <h4>Off-grid</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="main_body_container" id="C1">
            <div className="main_body_container_heading">
              <Jump>
                <h3>ABOUT US</h3>
              </Jump>
            </div>
            <div className="main_body_container_about">
              <div className="main_body_container_about_section">
                <div className="main_body_container_about_section_card">
                  <div className="main_body_container_about_section_card_left">
                    <div className="main_body_container_about_section_card_left_text">
                      <Fade left>
                        <p>
                          Nithin Sai Renewables (NSR) is a leading entity in
                          solar PV module manufacturing, operating under the
                          brand name Green N in India. Situated near Bangalore
                          International Airport, Karnataka, India, our
                          manufacturing facility boasts state-of-the-art
                          technology and a 100% automated production line.
                          <br />
                          <br />
                          We take pride in being pioneers in solar PV module
                          manufacturing, dedicated to advancing sustainable
                          energy solutions globally. With cutting-edge
                          technology, relentless innovation, and a profound
                          commitment to environmental stewardship, we actively
                          shape the future of solar energy. Our journey is
                          characterized by excellence, reliability, and an
                          unwavering dedication to delivering top-tier solar
                          modules that drive progress and illuminate a brighter,
                          cleaner world.
                          <br />
                          <br />
                          NSR is firmly committed to steering the nation towards
                          a sustainable future through the widespread adoption
                          of solar energy.
                          <br />
                          <br />
                          <h2>
                            <GoGoal /> Our mission is to produce top-tier,
                            reliable, and cost-effective solar PV modules.
                          </h2>
                          <br />
                          <p>
                            Our management team comprises individuals with a
                            combined experience of 55 years across various
                            domains, including manufacturing, management,
                            business development, technical expertise, and
                            infrastructure construction. We have successfully
                            executed greenfield projects in infrastructure,
                            solar parks, solar EPC, and the manufacturing of
                            power and solar equipment, as well as in the
                            pharmaceutical and chemical sectors.
                          </p>
                        </p>
                      </Fade>
                    </div>
                  </div>

                  <div className="main_body_container_about_section_card_right">
                    <div className="main_body_container_about_section_card_right_image">
                      <Slide right>
                        <img src={aboutus} alt="" />
                      </Slide>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sub" id="C2">
            <h3>Products</h3>
            <h2> MBB Mono PERC M10 Half- cell </h2>
            <p>Green72 PH500M,515W- 555W/MB/1500Vseries Mono Facial</p>
          </div>
          <div className="main_body_products">
            <div className="main_body_products_container">
              <div className="main_body_products_container_left">
                <div className="main_body_products_container_left_image">
                  <img src={bifacial} alt="bifacial" />
                </div>
              </div>

              <div className="main_body_products_container_right">
                <div className="main_body_products_container_right_heading">
                  <h2>MORE POWER</h2>
                </div>

                <div className="main_body_products_container_right_content">
                  <img src={icon1} alt="icon1" />

                  <p>Module power from 515W to 555W.</p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon2} alt="icon1" />

                  <p>Up to 5.2 % lower system cost.</p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon3} alt="icon1" />

                  <p>
                    Comprehensive LLD / Le TID mitigation <br />
                    technology, up to 50% lower degradation.
                  </p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon4} alt="icon1" />

                  <p>Cost effective product for utillity power plant.</p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon5} alt="icon1" />

                  <p>Less Shading effect.</p>
                </div>
                <span>MORE RELIABLE</span>
                <div className="main_body_products_container_right_content">
                  <img src={icon6} alt="icon1" />

                  <p>Minimizes micro-crack impacts.</p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon7} alt="icon1" />

                  <p>
                    Heavy snow load up to 5400P <br />
                    Heavy Load up to 2400 P.
                  </p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon8} alt="icon1" />

                  <p>Low temperature coefficent.</p>
                </div>
              </div>
            </div>
            <div className="main_body_products_section">
              <div className="main_body_products_section_content">
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon9} alt="f1" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Cylindrical</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      Tabbing wire is used reduces shadow on active cells and
                      increse the generation efficiency of the PV Module.
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon10} alt="f2" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Field Reliability</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      improved due to multi-contacts in the cell, reducing the
                      cell stress
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon17} alt="f3" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Minimal Power.</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>Loss due to low Internal Resistance</p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon12} alt="f4" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Industry Leading</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      30 Years linear performance warranty.Annual Degradation.
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon13} alt="f5" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>PID Resistance</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      Excellent Anti-PID performance guarantee via optimised
                      Mass-production process and materials control.
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={zero} alt="f1" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Zero Micro cracks & Minimal Effciency Loss</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>With a higher Busbars in PV Module.</p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon14} alt="f2" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Bypass Diodes</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      In split JB enable PV module to perform in partial shadow
                      conditions.
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon11} alt="f3" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Strong Quality</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>Check and Quality Assurance with pre and post EL test</p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon15} alt="f4" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>12 Years Product Warranty</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>For workmanship.</p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon16} alt="f5" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Higher Power Output</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      Module power increases 5-25% generally, bringing
                      significantly lower LCOE and higher IRR.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sub">
            <h2> MBB Mono PERC M10 Half- cell </h2>
            <p>
              Green72 PH500B,525- 555/MB/1500V series Bifacial Transperent
              Backsheet
            </p>
          </div>
          <div className="main_body_products">
            <div className="main_body_products_container">
              <div className="main_body_products_container_left">
                <div className="main_body_products_container_left_image">
                  <img src={monofacial} alt="monofacial" />
                </div>
              </div>

              <div className="main_body_products_container_right">
                <div className="main_body_products_container_right_heading">
                  <h2>MORE POWER</h2>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon20} alt="icon1" />
                  <p>Module power from 525W to 555W.</p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon2} alt="icon1" />
                  <p>Up to 5.2 % lower system cost.</p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon3} alt="icon1" />
                  <p>
                    Comprehensive LLD / Le TID mitigation <br />
                    technology, up to 50% lower degradation.
                  </p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon4} alt="icon1" />
                  <p>Cost effective product for utillity power plant.</p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon5} alt="icon1" />
                  <p>Less Shading effect.</p>
                </div>
                <span>MORE RELIABLE</span>
                <div className="main_body_products_container_right_content">
                  <img src={icon6} alt="icon1" />
                  <p>Minimizes micro-crack impacts.</p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon7} alt="icon1" />
                  <p>
                    Heavy snow load up to 5400 P<br />
                    Heavy Load up to 2400 P.
                  </p>
                </div>
                <div className="main_body_products_container_right_content">
                  <img src={icon8} alt="icon1" />
                  <p>Low temperature coefficent.</p>
                </div>
              </div>
            </div>
            <div className="main_body_products_section">
              <div className="main_body_products_section_content">
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon9} alt="f1" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Cylindrical</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      Tabbing wire is used reduces shadow on active cells and
                      increse the generation efficiency of the PV Module.
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon10} alt="f2" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Field Reliability</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      improved due to multi-contacts in the cell, reducing the
                      cell stress
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon17} alt="f3" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Minimal Power.</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>Loss due to low Internal Resistance</p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon12} alt="f4" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Industry Leading</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      30 Years linear performance warranty.Annual Degradation.
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon13} alt="f5" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>PID Resistance</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      Excellent Anti-PID performance guarantee via optimised
                      Mass-production process and materials control.
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={zero} alt="f1" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Zero Micro cracks & Minimal Effciency Loss</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>With a higher Busbars in PV Module.</p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon14} alt="f2" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Bypass Diodes</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      In split JB enable PV module to perform in partial shadow
                      conditions.
                    </p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon11} alt="f3" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Strong Quality</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>Check and Quality Assurance with pre and post EL test</p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon15} alt="f4" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>12 Years Product Warranty</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>For workmanship.</p>
                  </div>
                </div>
                <div className="main_body_products_section_content_cards">
                  <div className="main_body_products_section_content_cards_icon">
                    <img src={icon16} alt="f5" />
                  </div>
                  <div className="main_body_products_section_content_cards_text">
                    <h3>Higher Power Output</h3>
                  </div>
                  <div className="main_body_products_section_content_cards_data">
                    <p>
                      Module power increases 5-25% generally, bringing
                      significantly lower LCOE and higher IRR.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="main_body_products_data">
              <img src={data1} alt="data" />
            </div>
          </div>
          <div className="main_body_products_data">
            <img src={data} alt="data" />
            <img className="certi" src={certificate} alt="certificate" />
            <button className="btn" onClick={handelDownload}>
              Product Broucher
            </button>
          </div>
          <div className="main_body_count">
            <h1>Visitor Count: {count}</h1>
          </div>
          <div className="main_body_contact_box" id="C3">
            <div className="main_body_contact_box_heading">
              <h2>Contact Us</h2>
            </div>
            <div className="main_body_contact_box_details">
              <div className="main_body_contact_box_details_container">
                <div className="main_body_contact_box_details_container_content">
                  <div className="main_body_contact_box_details_container_content_topinputs">
                    <input
                      type="text"
                      placeholder="Your Name"
                      name="yourName"
                      value={formData.yourName}
                      onChange={handleChange}
                      required
                    />
                    <span>{errors.yourName}</span>
                  </div>
                  <div className="main_body_contact_box_details_container_content_topinputs">
                    <input
                      type="email"
                      placeholder=" Email-id"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <span>{errors.email}</span>
                  </div>
                  <div className="main_body_contact_box_details_container_content_topinputs">
                    <input
                      type="number"
                      placeholder="Mobile No"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      required
                      maxLength={10}
                      pattern="[0-9]*"
                    />
                    <span>{errors.mobileNumber}</span>
                  </div>
                </div>
                <div className="main_body_contact_box_details_container_content2">
                  <input
                    type="text"
                    placeholder="Company Name"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    required
                  />
                  <select
                    name="product"
                    id=""
                    value={formData.product}
                    onChange={handleChange}
                  >
                    <option hidden>Select Product</option>
                    <option value="Mono Facial">Mono Facial</option>
                    <option value="Bifacial">Bifacial</option>
                  </select>
                </div>
                <div className="main_body_contact_box_details_container_content3">
                  <input
                    type="text"
                    placeholder="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="main_body_contact_box_details_container_comment">
                  <textarea
                    name="comment"
                    value={formData.comment}
                    onChange={handleChange}
                    placeholder="Comment"
                    required
                  >
                    Comment
                  </textarea>
                </div>
                <div className="main_body_contact_box_details_container_button">
                  <button onClick={handleSubmit}>Submit</button>
                </div>
              </div>
            </div>
          </div>

          <div className="main_body_footer">
            <div className="main_body_footer_class">
              <div className="main_body_footer_class_section">
                <div className="main_body_footer_class_section_list">
                  <h3>Know more</h3>
                  <ul>
                    <li>
                      <a href="#H1">Home</a>
                    </li>
                    <li>
                      <a href="#C1">About us</a>
                    </li>
                    <li>
                      <a href="#C2">Products</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="main_body_footer_class_section">
                <div className="main_body_footer_class_section_list">
                  <h3>Corporate Office</h3>
                  <p>
                    152-3b, Srinagar Colony, 80ft Road,
                    <br />
                    Near Neeru Pragathi Park, Anantapur,
                    <br />
                    Andhra Pradesh, India, 515001
                  </p>
                </div>
              </div>
              <div class="main_body_footer_class_section">
                <div class="main_body_footer_class_section_list">
                  <h3>Factory</h3>
                  <p>
                    {" "}
                    Plot No 75-A, Masthenahally 2nd phase <br />
                    (KIADB) Industrial Area, Kaiwara Hobli, <br />
                    Chintamani Taluk, Chikkaballapura District - 563128
                  </p>
                </div>
              </div>
            </div>
            <div className="text">
              <p>info@nithinsairenewables.com</p>
            </div>
          </div>
          <div className="toaster">
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
