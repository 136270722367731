import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../components/custom/CustomToast";

const RouterComponent = () => {
  return (
     
    
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Customtoast" element={<CustomToast />} />
        </Routes>
      </Router>
      
    
   
  );
};

export default RouterComponent;
