import { toast } from "react-toastify";

const CustomToast = ({ message, type = "success", options = {} }) => {
  const commonOptions = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  };

  switch (type) {
    case "success":
      toast.success(message, {
        ...commonOptions,
        style: {
          borderLeft: "0.4vw solid #07bc0c",
          borderTopLeftRadius: "0.8vw",
          borderBottomLeftRadius: "0.8vw",
          ...commonOptions.style,
        },
      });
      break;
    case "error":
      toast.error(message, {
        ...commonOptions,
        style: {
          borderLeft: "0.4vw solid #ff0000",
          borderTopLeftRadius: "0.8vw",
          borderBottomLeftRadius: "0.8vw",
          ...commonOptions.style,
        },
      });
      break;
    default:
      console.error(`Unsupported toast type: ${type}`);
  }
};

export default CustomToast;