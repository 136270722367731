import "./App.css";
import RouterComponent from "./router/Router";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();

function App() {
  return (
    <div>
      <Helmet>
        <title>Nithin Sai | Best Solar and Renewable Energy Company</title>
        <meta
          property="og:title"
          content="Nithin Sai | Best Solar and Renewable Energy Company."
        />
        <meta property="og:site_name" content="Nithin Sai" />
        <meta property="og:url" content="https://www.nithinsairenewables.com/" />
        <meta
          property="og:description"
          content="Nithin Sai is the premier solar and renewable energy company, utilizing cutting-edge technology and AI to deliver superior performance and customer satisfaction worldwide. From home energy systems to large-scale power plant development, we offer end-to-end solutions with unmatched support."
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <RouterComponent />
      </QueryClientProvider>
    </div>
  );
}

export default App;
